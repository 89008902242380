import appStrings from '@/app/utility/string.utility';
import responseModal from '../../../shared/responseModal';
import commonHelper from '@/app/utility/common.helper.utility';
import addNfaApproval from '../../nfaApproval/addNfaApproval';
import DatePicker from 'vue2-datepicker';

export default {
  props: ['locationAllData', 'rfphdrid', 'itemAllData', 'vendorAllData'],
  components: {
    responseModal,
    addNfaApproval,
    DatePicker
  },
  data() {
    return {
      loading: false,
      country: {
        value: null,
        text: null
      },
      state: [],
      rfpState: [],
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      lessorCountry: {
        value: '100',
        text: 'India'
      },
      lessorState: {
        value: null,
        text: null
      },
      lessorCity: null,
      lessorPincode: null,
      region: [],
      rfpHeaderId: null,
      editMode: true,
      showOpenRegionModal: false,
      regionData: [
        {
          region: 'East'
        },
        {
          region: 'West'
        },
        {
          region: 'North'
        },
        {
          region: 'South'
        }
      ],
      regionFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'region',
          class: 'col-fix text-center'
        }
      ],
      selectAllRegion: false,
      rfpLocationFlag: false,
      rfpInitiateBidFlag: false,
      rfpClosedFlag: false,
      locationData: [
        {
          country_id: '100',
          country: 'India',
          region: null,
          state: null,
          location_id: 0,
          cost_centre: null,
          delete_flag: false,
          state_code: null
        }
      ],
      locationFields: [
        {
          key: 'add_row',
          label: 'Add',
          stickyColumn: true,
          class: 'col-fix-small text-center'
        },
        // {
        //   key: 'remove_row',
        //   label: 'Remove',
        //   stickyColumn: true,
        //   class: 'col-fix-small text-center'
        // },
        {
          key: 'delete_flag',
          label: 'Delete',
          class: 'col-fix text-center'
        },
        // {
        //   key: 'create_nfa',
        //   class: 'col-fix text-center'
        // },
        {
          key: 'country',
          class: 'col-fix text-center'
        },
        {
          key: 'region',
          class: 'col-fix text-center'
        },
        {
          key: 'state',
          class: 'col-fix text-center'
        },
        {
          key: 'cost_centre',
          class: 'col-fix text-center'
        }
      ],
      index: null,
      rfpStatesModal: false,
      rfpStatesData: [],
      rfpStatesFields: [
        {
          key: 'region',
          class: 'col-fix text-center'
        },
        {
          key: 'state_code',
          class: 'col-fix text-center'
        },
        {
          key: 'state',
          class: 'col-fix text-center'
        }
        // {
        //   key: 'state_id',
        //   class: 'col-fix text-center'
        // },
        // {
        //   key: 'state_tax_code',
        //   class: 'col-fix text-center'
        // }
      ],
      showOpenRfpStates: false,
      popoverContent: null,
      showOpenCostCentreModal: false,
      costCentreData: [],
      costCentreFields: [
        {
          key: 'state_code'
        },
        {
          key: 'cost_centre'
        }
      ],
      costCentreTotalRows: null,
      perPage: commonHelper.perPageRecordData,
      pageOptions: commonHelper.getPageOption(),
      costCentreCurrentPage: 1,
      countryIndex: null,
      showOpenSourcingApprovalModal: false,
      headerData: {},
      nfaData: {},
      showOpenCreateNfaModal: false,
      toDate: null,
      selectedLocation: {},
      nfaHeaderId: null,
      initialPrice: 0,
      finalPrice: 0,
      getNfaData: []
    };
  },
  watch: {
    costCentreCurrentPage: function() {
      this.getCostCentreData();
    },
    perPage: function() {
      this.costCentreCurrentPage = 1;
      this.getCostCentreData();
    }
  },
  created() {
    this.eventBus.$on('headerId', rfpHeaderId => {
      this.rfpHeaderId = rfpHeaderId;
    });
    this.eventBus.$on('rfpInitiateBidFlag', rfpInitiateBidFlag => {
      this.rfpInitiateBidFlag = rfpInitiateBidFlag;
    });
    this.eventBus.$on('rfpClosedFlag', rfpClosedFlag => {
      this.rfpClosedFlag = rfpClosedFlag;
      if (this.rfpClosedFlag) {
        this.locationFields = [
          {
            key: 'add_row',
            label: 'Add',
            stickyColumn: true,
            class: 'col-fix-small text-center'
          },
          // {
          //   key: 'remove_row',
          //   label: 'Remove',
          //   stickyColumn: true,
          //   class: 'col-fix-small text-center'
          // },
          {
            key: 'delete_flag',
            label: 'Delete',
            class: 'col-fix text-center'
          },
          {
            key: 'create_nfa',
            class: 'col-fix text-center'
          },
          {
            key: 'country',
            class: 'col-fix text-center'
          },
          {
            key: 'region',
            class: 'col-fix text-center'
          },
          {
            key: 'state',
            class: 'col-fix text-center'
          },
          {
            key: 'cost_centre',
            class: 'col-fix text-center'
          }
        ];
      }
    });
    // this.eventBus.$on('rfpSelectedState', rfpStateResponse => {
    //   this.locationData[this.index].region = rfpStateResponse.region;
    //   this.locationData[this.index].state = rfpStateResponse.state;
    //   this.locationData[this.index].state_code = rfpStateResponse.state_code;
    // });
    // this.eventBus.$on('rfpSelectedCostCentre', rfpSelectedCostCentre => {
    //   this.locationData[this.index].cost_centre =
    //     rfpSelectedCostCentre.cost_centre;
    // });
    this.eventBus.$on('headerData', headerData => {
      this.headerData = headerData;
    });
  },
  mounted() {
    this.rfpHeaderId = this.rfphdrid;
    if (this.locationAllData && this.locationAllData.length > 0) {
      this.getParentToChildData(this.locationAllData);
      this.rfpLocationFlag = true;
      this.eventBus.$emit('rfpLocationFlag', this.rfpLocationFlag);
      this.editMode = false;
    } else {
      this.rfpLocationFlag = false;
      this.eventBus.$emit('rfpLocationFlag', this.rfpLocationFlag);
    }
    if (this.rfpHeaderId) {
      this.getLocationDetails();
    }
  },
  methods: {
    openValueSetModal(vsetCode, index) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.index = index;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.RFP_STATE ||
        this.vsetCode === appStrings.VALUESETTYPE.RFP_REGION
      ) {
        this.parent_value_set_id = this.lessorCountry.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        this.lessorCountry.text = item.value_code;
        this.lessorCountry.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RFP_STATE) {
        this.locationData[this.index].state = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RFP_REGION) {
        this.locationData[this.index].region = item.value_code;
        this.locationData[this.index].state = null;
        this.locationData[this.index].state_code = null;
        this.locationData[this.index].cost_centre = null;
      }
      this.showValueSetModal = false;
    },
    postLocationDetailsData() {
      let count = 0;
      for (let i = 0; i < this.locationData.length; i++) {
        if (
          this.locationData[i].country === 'India' &&
          this.locationData[i].state === null &&
          this.locationData[i].region === null &&
          this.locationData[i].cost_centre === null
        ) {
          count++;
          this.countryIndex = i;
        }
      }

      if (count > 1) {
        alert('RFP already exist for India');
        this.locationData.splice(this.countryIndex, 1);
      } else {
        let locationDetails = [];
        let payload = {};
        for (let i of this.locationData) {
          locationDetails.push({
            location_id: i.location_id,
            country: 'India',
            country_id: '100',
            region: i.region,
            state: i.state,
            cost_centre: i.cost_centre,
            delete_flag: i.delete_flag,
            state_code: i.state_code
          });
        }
        payload = {
          rfp_header_id: this.rfpHeaderId,
          rfp_location_dtls: locationDetails
        };
        this.loading = true;
        this.$store
          .dispatch('rfp/postLocationDetails', payload)
          .then(response => {
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.makeToast(this.responseMsg, 'success');
              this.getLocationDetails();
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'danger');
              for (let i = 0; i < this.locationData.length; i++) {
                for (let j = 0; j < this.locationData.length; j++) {
                  if (
                    i !== j &&
                    this.locationData[i].region ===
                      this.locationData[j].region &&
                    this.locationData[i].state === this.locationData[j].state &&
                    this.locationData[i].cost_centre ===
                      this.locationData[j].cost_centre
                  ) {
                    this.locationData.splice(i, 1);
                  }
                }
              }
            }
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },

    // for (let i of this.locationData) {
    //   if (i.region === null && i.state.length === 0) {
    //     locationDetails.push({
    //       location_id: 0,
    //       country: 'India',
    //       country_id: '100',
    //       region: null,
    //       state: null
    //     });
    //   } else if (i.state.length === 0 && i.region !== null) {
    //     locationDetails.push({
    //       location_id: 0,
    //       country: 'India',
    //       country_id: '100',
    //       region: i.region,
    //       state: null
    //     });
    //   } else if (i.state.length !== 0 && i.region === null) {
    //     for (let j of i.state) {
    //       locationDetails.push({
    //         location_id: 0,
    //         country: 'India',
    //         country_id: '100',
    //         region: null,
    //         state: j
    //       });
    //     }
    //   } else if (i.state.length !== 0 && i.region !== null) {
    //     for (let j = 0; j < i.state.length; j++) {
    //       locationDetails.push({
    //         location_id: 0,
    //         country: 'India',
    //         country_id: '100',
    //         region: i.region,
    //         state: i.state[j]
    //       });
    //     }
    //   }
    // }

    getLocationDetails() {
      const payload = {
        headerId: this.rfpHeaderId
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getLocationDetails', payload)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.locationData = result;
            if (this.locationData.length === 0) {
              this.locationData.push({
                country_id: '100',
                country: 'India',
                region: null,
                state: null,
                location_id: 0,
                cost_centre: null,
                delete_flag: false,
                state_code: null
              });
              this.rfpLocationFlag = false;
              this.eventBus.$emit('rfpLocationFlag', this.rfpLocationFlag);
            } else {
              this.rfpLocationFlag = true;
              this.eventBus.$emit('rfpLocationFlag', this.rfpLocationFlag);
            }
            this.$store.commit('rfp/setStateRfp', this.state);

            this.editMode = false;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-center',
        appendToast: true,
        toastClass: 'custom-toast',
        autoHideDelay: 2000
      });
    },
    getParentToChildData(data) {
      this.lessorCountry = {
        value: '100',
        text: 'India'
      };
      if (data.length === 0) {
        this.locationData.push({
          country_id: '100',
          country: 'India',
          region: null,
          state: null,
          location_id: 0,
          cost_centre: null,
          delete_flag: false,
          state_code: null
        });
      } else {
        for (let i = 0; i < data.length; i++) {
          this.region.push(data[i].region);
          this.state.push(data[i].state);
          this.rfpState.push({
            value: data[i].location_id,
            text: data[i].state
          });
        }
      }
      this.$store.commit('rfp/setStateRfp', this.rfpState);
    },
    alterEditMode() {
      this.editMode = !this.editMode;
    },
    regionModal(flag) {
      this.showOpenRegionModal = flag;
    },
    openRegionModal() {
      this.showOpenRegionModal = true;
    },
    selectRegionChecked(flag, index, item) {
      this.regionData[index].select = flag;
      if (flag) {
        this.region.push(item.region);
      } else {
        this.region = this.region.filter(el => el !== item.region);
      }
    },
    addNewRow() {
      this.locationData.push({
        country_id: '100',
        country: 'India',
        region: null,
        state: null,
        location_id: 0,
        cost_centre: null,
        delete_flag: false,
        state_code: null
      });
    },
    removeRow(index) {
      this.locationData.splice(index, 1);
    },
    clearRegion(index) {
      this.locationData[index].region = null;
    },
    clearState(index) {
      this.locationData[index].state = null;
      this.locationData[index].state_code = null;
    },
    openCloseResponseModal(item, region, index) {
      this.index = index;
      if (item === 'RFP_STATES') {
        this.index = index;
        this.showOpenRfpStates = true;
        this.getRfpStatesData(region);
      } else if (item === 'COST_CENTRE') {
        this.getCostCentreData();
        this.showOpenCostCentreModal = true;
      }
    },
    closeRfpStatesModal(flag) {
      this.showOpenUserDepartment = flag;
    },
    getRfpStatesData(region) {
      const payload = {
        region: region
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getRfpStates', payload)
        .then(response => {
          if (response.status === 200) {
            this.rfpStatesData = response.data.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectedRfpState(flag, selectedState) {
      this.locationData[this.index].state = selectedState.state;
      this.locationData[this.index].region = selectedState.region;
      this.locationData[this.index].state_code = selectedState.state_code;
      this.locationData[this.index].cost_centre = selectedState.cost_centre;
      this.showOpenRfpStates = flag;
    },
    getKeySummaryDtls(value) {
      const payload = {
        tag: value,
        menu_id: 446
      };
      this.loader = true;
      this.$store
        .dispatch('rfp/getKeySummaryDtls', payload)
        .then(resp => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            let result = resp.data.data;
            this.popoverContent = result;
          } else {
            this.helpText = 'Something Went Wrong.';
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = 'Something Went Wrong.';
        });
    },
    costCentreModal(flag) {
      this.showOpenCostCentreModal = flag;
    },
    selectedCostCentre(flag, selectedCostCentre) {
      this.locationData[this.index].state_code = selectedCostCentre.state_code;
      this.locationData[this.index].cost_centre =
        selectedCostCentre.cost_centre;
      this.showOpenCostCentreModal = flag;
    },
    getCostCentreData() {
      const payload = {
        _page: this.costCentreCurrentPage - 1,
        _limit: this.perPage,
        state_code: this.locationData[this.index].state_code
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/getCostCentreData', payload)
        .then(response => {
          if (response.status === 200) {
            this.costCentreData = response.data.data.data.page;
            this.costCentreTotalRows = response.data.data.data.total_elements;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initiateNFA(item) {
      this.showOpenCreateNfaModal = true;
      this.selectedLocation = item;
    },
    closeSourcingApprovalModal(flag) {
      this.showOpenSourcingApprovalModal = flag;
    },
    deleteLocation(item) {
      item.delete_flag = !item.delete_flag;
    },
    closeCreateNfaModalModal(flag) {
      this.showOpenCreateNfaModal = flag;
    },
    createNfa() {
      const payload = {
        nfa_hdr_id: 0,
        le_id: this.headerData.le_id,
        nfa_zone: this.selectedLocation.region
          ? this.selectedLocation.region
          : 'All Zones',
        end_date: this.toDate,
        nfa_type_vset: this.headerData.rfp_type,
        nfa_objective: null,
        nfa_summary: this.headerData.description,
        approval_note: null,
        location_id: this.selectedLocation.location_id
      };
      this.loading = true;
      this.$store
        .dispatch('intWorkflow/addEditSourcingApprovalForHeader', payload)
        .then(response => {
          if (response.status === 200) {
            this.nfaHeaderId = response.data.data.nfa_hdr_id;
            this.getNfaDetails(this.nfaHeaderId);
            // this.getUnitPrices();
            // this.getNfaDetailsData(this.nfaHeaderId);
            this.postRfpNfaData();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getNfaDetails(nfaheaderid) {
      this.loading = true;
      this.$store
        .dispatch('intWorkflow/getNfaHdrDetails', nfaheaderid)
        .then(response => {
          if (response.status === 200) {
            let result = response.data.data;
            this.nfaData = {
              leName: result.le_name,
              leId: result.le_id,
              zone: result.nfa_zone,
              startDate: this.headerData.start_date,
              endDate: result.end_date,
              rfpType: result.nfa_type_vset,
              rfpMeaning:
                result.nfa_type_vset === 'NRC_OE' ||
                result.nfa_type_vset === 'NRC_CE'
                  ? 'Non Rate Contract'
                  : 'Rate Contract'
            };
            this.showOpenSourcingApprovalModal = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postRfpNfaData() {
      let payload = {
        rfp_header_id: this.rfpHeaderId,
        location_id: this.selectedLocation.location_id
      };
      this.loading = true;
      this.$store
        .dispatch('rfp/postRfpNfaData', payload)
        .then(response => {
          if (response.status === 200) {
            this.getNfaData = response.data.data;
            this.postNfaDetails();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postNfaDetails() {
      const payload = {
        nfa_hdr_id: this.getNfaData.nfa_hdr_id,
        item_lists: this.getNfaData.item_lists
      };
      this.loading = true;
      this.$store
        .dispatch('intWorkflow/addEditSourcingApprovalForDetails', payload)
        .then(response => {
          if (response.status === 200) {
            this.temp = response;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
    // getNfaDetailsData(nfaHeaderId) {
    //   let itemLists = [];
    //   for (let i of this.itemAllData) {
    //     let vendordata = [];
    //     for (let j of this.vendorAllData) {
    //       this.getUnitPrices(j.name, i.item_code);
    //       vendordata.push({
    //         vendor_item_dtl_id: 0,
    //         vendor_id: j.vendor_code,
    //         new_vendor: j.is_new_vendor,
    //         vendor_name: j.name,
    //         initial_price: 0,
    //         final_offer: 1.0,
    //         last_purchase_price: 0,
    //         bussiness_share: 0,
    //         freight: null,
    //         payment_term_id: '1',
    //         freight_term_id: null,
    //         additional_details: null,
    //         approval_status: true
    //       });
    //     }
    //     itemLists.push({
    //       nfa_item_id: 0,
    //       inventory_item_id: i.item_id,
    //       item_spec: i.item_description,
    //       quantity: i.quantity,
    //       tax_rate: '0',
    //       data: vendordata
    //     });
    //   }
    //   const payload = {
    //     nfa_hdr_id: nfaHeaderId,
    //     item_lists: itemLists
    //   };
    //   this.loading = true;
    //   this.$store
    //     .dispatch('intWorkflow/addEditSourcingApprovalForDetails', payload)
    //     .then(response => {
    //       if (response.status === 200) {
    //         this.showOpenSourcingApprovalModal = true;
    //       }
    //       this.loading = false;
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
    // getUnitPrices(name, itemCode) {
    //   let payload = {
    //     rfp_header_id: this.rfpHeaderId,
    //     negotiation_seq: null
    //   };
    //   this.loading = true;
    //   this.$store
    //     .dispatch('rfp/getRfpNewComparative', payload)
    //     .then(response => {
    //       if (response.status === 200) {
    //         let result = response.data.data;
    //         let maxSeq = 0;
    //         let maxVer = 0;
    //         for (let i of result) {
    //           if (
    //             i.name === name &&
    //             i.item_code === itemCode &&
    //             i.negotiation_seq === 1 &&
    //             i.version === 1 &&
    //             this.selectedLocation.location_name === i.location_name
    //           ) {
    //             this.initialPrice = i.unit_price;
    //           } else if (
    //             i.negotiation_seq > maxSeq &&
    //             i.version > maxVer &&
    //             i.name === name &&
    //             i.item_code === itemCode &&
    //             this.selectedLocation.location_name === i.location_name
    //           ) {
    //             this.finalPrice = i.unit_price;
    //           }
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loading = false;
    //     });
    // },
  },
  beforeDestroy() {
    this.eventBus.$off('rfpLocationFlag');
  }
};
